<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Surat Masuk</h3>
            <hr class="m-t-0 m-b-40">

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.pengirim" name="pengirim" required type="text" label="Pengirim"/>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.tanggal_diterima" name="tanggal_diterima" required type="date" label="Tanggal Diterima" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.kepada" name="kepada" required type="text" label="Kepada" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.tujuan" name="tujuan" required type="text" label="Tujuan" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.tembusan" name="tembusan" type="text" label="Tembusan" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Indeks
                    <small style="color:red">*</small>
                  </label>
                  <div :class="['col-md-9',{ 'invalid': isInvalidIndeks }]">
                    <Multiselect
                      placeholder="Pick at least one"
                      select-label="Enter doesn’t work here!"
                      :value="payload.valueIndeks"
                      track-by="id"
                      label="text"
                      :options="optionIndeks"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeIndeks"
                      @close="onTouch"
                    ></Multiselect>
                    <label
                      class="typo__label form__label"
                      v-show="isInvalidIndeks"
                    >Must have at least one value</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.nomor_surat" name="nomor_surat" type="text" label="Nomor Surat"/>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.nomor_dokumen" name="nomor_dokumen" type="text" label="Nomor Dokumen" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.tanggal_dokumen" name="tanggal_dokumen" type="date" label="Tanggal Dokumen" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.perihal" name="perihal" type="text" label="Perihal" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.keterangan" name="keterangan" type="text" label="Keterangan"/>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="['control-label', {'text-right': !isMobile}, 'col-md-3']"
                  >File</label>
                  <div class="col-md-9 col-sm-9">
                    <Upload v-model="payload.file" required isFile name="file"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Simpann">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.suratMasuk;
    },
    optionIndeks() {
      const indeks = JSON.parse(localStorage.getItem('refUnit'));
      const indeksDropdown = [];
      if (indeks) {
        indeks.forEach((value) => {
          indeksDropdown.push({ id: value.id, text: value.name });
        });
      }

      return indeksDropdown;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidIndeks() {
      return this.isTouched && this.payload.valueIndeks.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        pengirim: "",
        tanggal_diterima: "",
        kepada: "",
        tujuan: "",
        tembusan: "",
        valueIndeks: [],
        nomor_surat: "",
        nomor_dokumen: "",
        tanggal_dokumen: "",
        perihal: "",
        keterangan: ""
      },
      myFile: [],
      isTouched: false,
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/suratMasuk/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("suratMasuk/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("suratMasuk/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        pengirim: data.pengirim,
        
      };
    },
    onTouch() {
      this.isTouched = true;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeIndeks(value) {
      this.payload.valueIndeks = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueIndeks = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("suratMasuk/onCancel");
    },
    submit() {
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        pengirim: this.payload.pengirim,
        tanggal_diterima: this.payload.tanggal_diterima,
        kepada: this.payload.kepada,
        tujuan: this.payload.tujuan,
        tembusan: this.payload.tembusan,
        indeks:  parseInt(this.payload.valueIndeks.id),
        nomor_surat: this.payload.nomor_surat,
        nomor_dokumen: this.payload.nomor_dokumen,
        tanggal_dokumen: this.payload.tanggal_dokumen,
        perihal: this.payload.perihal,
        keterangan: this.payload.keterangan,
        file: this.myFile[1]
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("suratMasuk/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("suratMasuk/submitAdd", data);
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

